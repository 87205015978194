import './App.css';
import { Form } from './components';

function App() {
  return (
    <Form />
  );
}

export default App;
