import React from 'react';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Image,
  Slide,
  Slider,
} from 'pure-react-carousel';
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import 'pure-react-carousel/dist/react-carousel.es.css';
import clsx from 'clsx';

export default function Carousel( { choosen, employees, setChoosen } ) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CarouselProvider
        visibleSlides={ 2 }
        totalSlides={ employees.length }
        step={ 2 }
        naturalSlideWidth={ 150 }
        naturalSlideHeight={ 200 }
        hasMasterSpinner
      >
        <div className={ classes.container }>
          <Slider className={ classes.slider }>
            { employees.map( ( employee )  =>
              <Slide index={ employee.id } onClick={ () => setChoosen( employee.id ) }>
                <div className={ clsx( classes.imageWrapper, choosen === employee.id && classes.selectedItem ) }>
                  <Image className={ classes.image } src={ employee.photo } />
                </div>
                <Typography variant="subtitle1" align="center">{ employee.lastname + ' ' + employee.firstname + ' ' + employee.surname }</Typography>
                <Typography variant="body2" align="center">{ employee.description }</Typography>
              </Slide>
              )
            }
            {/*<Slide index={1} onClick={ () => setChoosen(2) }>
              <div className={ clsx( classes.imageWrapper, choosen === 2 && classes.selectedItem ) }>
                <Image className={ classes.image } src="https://nikor-n.ru/wp-content/uploads/2017/08/shulgach.png" />
              </div>
              <Typography variant="subtitle1" align="center">Шульгач Андрей Васильевич</Typography>
              <Typography variant="body2" align="center">врач стоматолог-терапевт</Typography>
            </Slide>
            <Slide index={2} onClick={ () => setChoosen(3) }>
              <div className={ clsx( classes.imageWrapper, choosen === 3 && classes.selectedItem ) }>
                <Image className={ classes.image } src="https://nikor-n.ru/wp-content/uploads/2017/08/Tiholaz.png" />
              </div>
              <Typography variant="subtitle1" align="center">Тихолаз Юрий Юрьевич</Typography>
              <Typography variant="body2" align="center">врач стоматолог-хирург, имплантолог</Typography>
            </Slide>
            <Slide index={3} onClick={ (e) => setChoosen(4) }>
              <div className={ clsx( classes.imageWrapper, choosen === 4 && classes.selectedItem ) }>
                <Image className={ classes.image } src="https://nikor-n.ru/wp-content/uploads/2017/08/soldatov.png" />
              </div>
              <Typography variant="subtitle1" align="center">Солдатов-Федотов Никита Владиславович</Typography>
              <Typography variant="body2" align="center">врач стоматолог-ортопед</Typography>
            </Slide>
            <Slide index={4} onClick={ () => setChoosen(5) }>
              <div className={ clsx( classes.imageWrapper, choosen === 5 && classes.selectedItem ) }>
                <Image className={ classes.image } src="https://nikor-n.ru/wp-content/uploads/2018/07/Kobyfa.png" />
              </div>
              <Typography variant="subtitle1" align="center">Кобыфа Александр Валерьевич</Typography>
              <Typography variant="body2" align="center">врач стоматолог-терапевт</Typography>
            </Slide>
            <Slide index={5} onClick={ () => setChoosen(6) }>
              <div className={ clsx( classes.imageWrapper, choosen === 6 && classes.selectedItem ) }>
                <Image className={ classes.image } src="https://nikor-n.ru/wp-content/uploads/2017/08/Kostyuhina.png" />
              </div>
              <Typography variant="subtitle1" align="center">Костюхина Юлиана Александровна</Typography>
              <Typography variant="body2" align="center">врач стоматолог-терапевт</Typography>
            </Slide>*/}
          </Slider>
          <ButtonBack className={ classes.buttonBack }><IconButton><ChevronLeft /></IconButton></ButtonBack>
          <ButtonNext className={ classes.buttonNext }><IconButton><ChevronRight /></IconButton></ButtonNext>
        </div>
        <DotGroup className={ classes.dotGroup } />
      </CarouselProvider>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    maxWidth: '800px',
    margin: 'auto'
  },
  slider: {
  },
  buttonBack: {
    position: 'absolute',
    top: '50%',
    left: -theme.spacing(3),
    transform: 'translateY(-50%)',
    display: 'inline-block',
    border: 'none',
    background: 'transparent',
    '&:hover, &:focus, &:active': {
      outline: 'none',
    }
  },
  image: {
    borderRadius: 225,
  },
  imageWrapper: {
    maxHeight: 225,
    borderRadius: 225,
    borderWidth: theme.spacing(1),
    borderColor: 'transparent',
    borderStyle: 'solid',
    overflow: 'hidden',
    margin: theme.spacing(2),
    '&:hover': {
      borderColor: theme.palette.primary.main
    },
  },
  selectedItem: {
    borderColor: theme.palette.primary.main
  },
  buttonNext: {
    position: 'absolute',
    top: '50%',
    right: -theme.spacing(3),
    transform: 'translateY(-50%)',
    border: 'none',
    background: 'transparent',
    '&:hover, &:focus, &:active': {
      outline: 'none',
    }
  },
  dotGroup: {
    textAlign: 'center',
    '& button': {
      width: theme.spacing(2),
      height: theme.spacing(2),
      margin: theme.spacing(1),
      border: 'none',
      borderRadius: theme.spacing(2),
      '&:hover, &:focus, &:active': {
        outline: 'none',
      }
    },
  }
}));