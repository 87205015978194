import React, { useState, useEffect } from 'react';
import {
  Button,
  CssBaseline,
  Paper,
  Box,
  Grid,
  Link,
  Hidden,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import ru from 'react-phone-number-input/locale/ru'
import { PhoneNumber, Carousel, Modal, Promo, Waiter, SuccessModal } from '../components';
import WeekCalendar from 'react-week-calendar';
import './Calendar.css';
import axios from 'axios';
import moment from 'moment';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://material-ui.com/">
        Группа компаний "Никор"
      </Link>
      {' © ' + new Date().getFullYear() }
    </Typography>
  );
}

export default function Form() {
  const classes = useStyles(),
  [ agreed, setAgreed ] = useState(false),
  [ formData, setFormData ] = useState(null),
  [ filial, setFilial ] = useState(1),
  [ waiter, setWaiter ] = useState( true ),
  [ choosen, setChoosen ] = useState(null),
  [ phone, setPhone ] = useState(''),
  [ name, setName ] = useState(''),
  [ success, setSuccess ] = useState( null );

  const handleSubmit = (e) => {
    e.preventDefault();
    if ( isValidPhoneNumber( phone ) ) {
      axios.post('https://api.nikor-n.ru/api/leads', {
        name: name,
        phone: phone,
        employee_id: choosen,
        filial_id: filial
      });
      //const text = 'Телефон: ' + phone + '\nФИО: ' +  name + '\n\nФилиал: ' + filial + '\nВрач: ' + employee + '\n\nДата: ' + start.format('Do MMMM') + '\nВремя: ' + start.format('HH:mm') + ' - ' + end.format('HH:mm') + '\n\nКомментарий: ' + comment; 
      //axios.get('https://api.telegram.org/bot' + token + '/sendMessage?chat_id=' + user_id + '&text=' + encodeURI(text))
      //
      //token = '346369267:AAFi1SyUNisz7bYvGcmlkcEjTBHUuSPug00',
      //user_id = 2662066;
    }
  }

  useEffect (
    () => {
      if( isValidPhoneNumber( phone ) ) {
        axios.get('https://api.nikor-n.ru/api/phone?phone=' + phone)
          .then(function ( response ) {
            const client = response.data;
            setName( client.lastname + ' ' + client.firstname + ' ' + client.surname );
          })
      }
    },
    [ phone ]
  );

  useEffect (
    () => {
      axios.get('https://api.nikor-n.ru/api/formData')
        .then(function ( response ) {
          setFormData( response.data );
          setWaiter( false );
        })
    },
    [ setFormData ]
  );

  return (
    <React.Fragment>
      { !waiter ?
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          { success &&
            <SuccessModal { ...{ name, employee: formData?.Employees[ choosen ], filial: formData?.Filials[ filial ], success, setSuccess } } />
          }
          { choosen ?
            <Grid item xs={ 12 } md={8} className={ classes.online }>
              <WeekCalendar
                scaleUnit={ 30 }
                eventSpacing={ 30 }
                dayFormat='DD.MM'
                cellHeight={ 33 }
                showModalCase={ ['create'] }
                startTime={ moment({h: 8, m: 0}) }
                endTime={ moment({h: 20, m: 1}) }
                selectedIntervals={[
                  {
                    uid: 1,
                    start: moment({h: 13, m: 0}),
                    end: moment({h: 14, m: 0}),
                    value: "Обед"
                  },
                  {
                    uid: 2,
                    start: moment({h: 13, m: 0}).add(1,'d'),
                    end: moment({h: 14, m: 0}).add(1,'d'),
                    value: "Обед"
                  },
                  {
                    uid: 3,
                    start: moment({h: 13, m: 0}).add(2,'d'),
                    end: moment({h: 14, m: 0}).add(2,'d'),
                    value: "Обед"
                  },
                  {
                    uid: 4,
                    start: moment({h: 13, m: 0}).add(3,'d'),
                    end: moment({h: 14, m: 0}).add(3,'d'),
                    value: "Обед"
                  },
                  {
                    uid: 5,
                    start: moment({h: 13, m: 0}).add(4,'d'),
                    end: moment({h: 14, m: 0}).add(4,'d'),
                    value: "Обед"
                  },
                  {
                    uid: 6,
                    start: moment({h: 13, m: 0}).add(5,'d'),
                    end: moment({h: 14, m: 0}).add(5,'d'),
                    value: "Обед"
                  },
                  {
                    uid: 7,
                    start: moment({h: 13, m: 0}).add(6,'d'),
                    end: moment({h: 14, m: 0}).add(6,'d'),
                    value: "Обед"
                  },
                ]}
                modalComponent={ ( { start, end, onSave } ) => <Modal { ...{ start, end, onSave, phone, name, employee: formData.employees[ choosen ], filial: formData.filials[ filial ], setWaiter, setSuccess } } /> }
              />
            </Grid>
            :
            <Promo {...{ promo: formData.promo }} />
          }
          <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
            <div className={ classes.paper }>
              <Typography variant="h3">
                Онлайн-запись
              </Typography>
              <Typography variant="h5" className={ classes.title }>
                сеть стоматологических клиник "Никор"
              </Typography>
              <form className={ classes.form }>
                <Grid container spacing={ 2 }>
                  <Grid item xs={12}>
                    <Input
                       label='Контактный телефон'
                       placeholder='8 (901) 234-56-78'
                       value={ phone }
                       onChange={ setPhone }
                       country="RU"
                       defaultCountry="RU"
                       withCountryCallingCode
                       labels={ ru }
                       required
                       inputComponent={ PhoneNumber }
                    />
                    <FormControlLabel
                      control={<Checkbox onChange={ () => setAgreed(!agreed) } value={ agreed } color="primary" />}
                      className={ classes.agree }
                      label={ ( <div> <span>Я согласен(на) с соглашением на обработку <Link target='_blank' href='https://nikor-n.ru/politika-konfidentsialnosti/'>персональных данных</Link></span></div> ) }
                    />
                  </Grid>
                  { agreed && 
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={ handleSubmit }
                          className={classes.submit}
                          disabled={ isValidPhoneNumber( phone ) ? false : true  }
                        >
                          Отправить заявку
                        </Button>
                      </Grid>
                    }
                  { ( isValidPhoneNumber( phone ) && agreed ) &&
                    <React.Fragment>
                      <Grid item xs={12}>
                        <TextField
                          id="fio"
                          fullWidth
                          variant="outlined"
                          label="Фамилия Имя Отчество"
                          value={ name }
                          onInput={ (e) => setName( e.target.value ) }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="filial"
                          select
                          fullWidth
                          variant="outlined"
                          label="Выберите ближайший филиал"
                          value={ filial }
                          onChange={ (e) => setFilial( e.target.value ) }
                        >
                        {
                          formData.filials.map( ( filial ) => {
                            return <MenuItem key={ filial.id } value={ filial.id }>{ filial.description }</MenuItem>;
                          })
                        }
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Carousel {...{ choosen, employees: formData.filials[ filial ]['employees'], setChoosen }} />
                      </Grid>
                    </React.Fragment>
                }
                  <Grid item xs={12}>
                    <Typography variant="body2" className={ classes.disclaimer }>
                      Запись через сайт является предварительной. Для отправки заявки достаточно номера телефона. Наш сотрудник свяжется с Вами для подтверждения записи к специалисту.
                    </Typography>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
        :
        <Waiter />
      }
    </React.Fragment>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    //backgroundImage: 'url(https://nikor-n.ru/wp-content/uploads/2020/09/2020-11-19-5.45.13.jpeg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'contain',
    backgroundPosition: 'centerTop',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100vh',
  },
  online: {
    height: '100vh',
    overflow: 'auto'
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    overflow: 'auto'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  readmore: {
    right: theme.spacing(4),
    bottom: theme.spacing(12)
  },
  agree: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  disclaimer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
}));

