import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from './success.json'
import { Paper, Box, Button, Grid, Typography, Modal } from '@material-ui/core';
import 'moment/locale/ru';


export default function SuccessModal( { value, name, employee, filial, success, setSuccess } ) {

  const handleClose = (e) => {
    setSuccess( false );
  };

  return (
    <Modal
      open={ success }
      onClose={ handleClose }
    >

      <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item xs={10} md={4}>
          <Paper elevation={ 8 }>
            <Box p={3}>
              <Box p={2}>
                <Typography variant="h4" align="center">
                  Заявка успешно отправлена!
                </Typography>
              </Box>
              <Grid container justify="center" alignItems="center">
                <Lottie
                  loop
                  animationData={ animationData }
                  play
                  style={{ width: 250, height: 250 }}
                />
              </Grid>
              <Box mb={1}>
                <Typography variant="subtitle1" align="center">
                  Скоро с Вами свяжется сотрудник коллцентра для подтверждения записи.
                </Typography>
              </Box>
              <Box p={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={ handleClose }
                >
                  Спасибо
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
}
