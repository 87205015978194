import React from 'react';
import {
  Button,
  Grid,
  Hidden,
  makeStyles
} from '@material-ui/core';

export default function Promo( { promo } ) {
  const classes = useStyles();

  return (
    <Grid item xs={false} sm={4} md={8} className={ classes.image } style={{ backgroundImage: 'url(' + promo.image + ')' }}>
      <Hidden only="xs">
        <Button
          color="primary"
          variant="contained"
          className={ classes.readmore }
          onClick={ () => window.open(promo.link, '_blank') }
        >
          Подробнее
        </Button>
      </Hidden>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  readmore: {
    right: theme.spacing(4),
    bottom: theme.spacing(12)
  },
  image: {
    //backgroundImage: 'url(https://nikor-n.ru/wp-content/uploads/2020/09/2020-11-19-5.45.13.jpeg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'contain',
    backgroundPosition: 'centerTop',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100vh',
  },
}));