import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Grid, Typography } from '@material-ui/core';
import 'moment/locale/ru';
import axios from 'axios';


export default function Modal( { start, end, input, value, phone, name, employee, filial, setWaiter, onSave, setSuccess } ) {

  const [ comment, setComment ] = useState('');

  useEffect (
    () => {
      start.locale('ru');
      end.locale('ru');
      //console.warn( doctor );
    },
    [ start, end ]
  );
  const handleSubmit = (e) => {
    setWaiter( true );
    e.preventDefault();
      axios.post('https://api.nikor-n.ru/api/leads', {
        name: name,
        phone: phone,
        employee_id: employee.id,
        filial_id: filial.id,
        date: start.format('YYYY-MM-DD'),
        time: start.format('HH:mm') + ' - ' + end.format('HH:mm'),
        comment: comment
      })
      .then(function ( response ) {
        if( response.data.status === true) {
          onSave();
          setWaiter( false );
          setSuccess( true );
        }
      });
  }

  return (
    <div className="customModal">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Подтвердите введенные данные
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={2} md={4}>
              <Typography variant="subtitle1">
                ФИО:
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Typography variant="h6">
                { name }
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={2} md={4}>
              <Typography variant="subtitle1">
                Адрес:
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Typography variant="h6">
                { filial.description }
              </Typography>
            </Grid>
            <Grid item xs={2} md={4}>
              <Typography variant="subtitle1">
                Врач:
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Typography variant="h6">
                { employee.lastname + ' ' + employee.firstname + ' ' + employee.surname }
              </Typography>
            </Grid>
        </Grid>
      </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={3} md={6}>
              <Typography variant="subtitle1">
                Дата:
              </Typography>
            </Grid>
            <Grid item xs={9} md={6}>
              <Typography variant="h6">
                { start.format('Do MMMM') }
              </Typography>
            </Grid>
            <Grid item xs={3} md={6}>
              <Typography variant="subtitle1">
                Время:
              </Typography>
            </Grid>
            <Grid item xs={9} md={6}>
              <Typography variant="h6">
                { start.format('HH:mm') + ' - ' + end.format('HH:mm') }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} md={8}>
            <TextField
              size="small"
              value={ comment }
              className="customModal__input"
              type="text"
              variant="outlined"
              onInput={ (e) => setComment(e.target.value) }
              label="Введите комментарий"
              defaultValue={ comment }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              //className="customModal__button customModal__button_float_right"
              onClick={ handleSubmit }
            >
              Отправить заявку
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
