import { createMuiTheme } from '@material-ui/core/styles';


export default createMuiTheme ({
	palette: {
		primary: {
			main: '#5CA836'
		}
	},
	overrides: {
		MuiButton: {
			label: {
				color: 'white',
			},
		},
	},
});
